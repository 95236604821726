import React, { ReactElement, useEffect } from 'react'

import {
  WeeklyRecipes,
  Partnerships,
  BottomPageCTASection,
  TrustpilotCarousel,
  TrustPilotMicroCombo,
  EmailCapture,
  HomepageLayout,
  BBCGoodFoodWelcome,
  BBCGoodFoodHero,
} from '@mindfulchefuk/features/Homepage/components'

import {
  HomepagePromotionalBanner,
  ProductAnnouncementModal,
  MultiCardGroup,
} from '@mindfulchefuk/features/CMS/components'

import { useApplyVoucher } from '@mindfulchefuk/features/Basket'
import { Box } from '@mindfulchefuk/design-system'
import { useCMSHomepageVariant } from '@mindfulchefuk/features/CMS/hooks/useCMSHomepageVariant'
import { REFERRER_PATH_KEY } from '@mindfulchefuk/hooks/useReferrer'
import { trackAnalyticsEvent } from '@mindfulchefuk/utils/analytics/amplitude/amplitudeAdapter'
import { bbcGoodFoodPartnerships } from './data/partnerships'
import { EmphasisLinebreakText } from './components/EmphasisLinebreakText'

interface Props {
  voucherCode?: string
}

export const BBCGoodFoodHomepage = ({ voucherCode }: Props): ReactElement => {
  useEffect(() => {
    window.sessionStorage.setItem('isBBCGoodFoodSession', 'true')
  }, [])

  useApplyVoucher(voucherCode)

  const { howItWorks, standout, weeklyRecipesSubheading } =
    useCMSHomepageVariant()

  useEffect(() => {
    const pageSource = window.sessionStorage.getItem(REFERRER_PATH_KEY)

    trackAnalyticsEvent({
      name: 'BBCGF landing page viewed',
      data: { rawPageSource: pageSource },
    })
  }, [])

  return (
    <>
      <HomepageLayout>
        <Box color="bbc-good-food-black">
          <BBCGoodFoodHero />

          <BBCGoodFoodWelcome />

          <TrustpilotCarousel ctaLocation="BBC homepage trustpilot CTA" />

          <HomepagePromotionalBanner />

          <WeeklyRecipes
            title="There's something for everyone"
            titleEmphasis="something for everyone"
            subtitle={weeklyRecipesSubheading}
            subtitleEmphasis="High in protein, no refined sugars and gluten free"
            ctaLocation="BBC homepage weekly recipe CTA"
          />

          <MultiCardGroup
            data={howItWorks}
            useCms
            titleColor="bbc-good-food-black"
            bodyColor="bbc-good-food-black"
            ctaLocation="BBC homepage how it works CTA"
          />

          <MultiCardGroup
            data={standout}
            useCms
            titleColor="bbc-good-food-pink"
            bodyColor="bbc-good-food-black"
            mobileStacking
            ctaLocation="BBC homepage what makes MC stand out CTA"
          />

          <Box
            textAlign="center"
            mt={8}
            mb={40}
            mx="auto"
            px={{ base: 24 }}
            maxWidth="730px"
          >
            <EmphasisLinebreakText
              mb={40}
              text="Change your weeknights for good. No stress, no shopping. Just feel-good food delivered to your door."
              emphasisText="No stress, no shopping."
              variant={{ base: 'heading-sm', sm: 'heading-md' }}
              emphasisVariant={{ base: 'heading-md', sm: 'heading-lg' }}
              mt={40}
            />
            <TrustPilotMicroCombo />
          </Box>

          <Partnerships data={bbcGoodFoodPartnerships} />

          <BottomPageCTASection ctaLocation="BBC homepage bottom of the page CTA" />

          <EmailCapture color="white" isBBCGoodFood source="MC_homepage" />
        </Box>
      </HomepageLayout>

      <ProductAnnouncementModal page="page-homepage" />
    </>
  )
}
